import { useEffect, useMemo, useRef, useState } from 'react'
import { Data, getStackTrace } from '@gijirokukun/shared'
import { DocumentData, Query } from 'uni-firebase/firestore'
// eslint-disable-next-line no-restricted-imports
import { queryEqual } from 'firebase/firestore'
import { FirestoreError } from 'src/api/loggingFirestore'

const snapshotOptions = {
  serverTimestamps: 'estimate',
} as const

export const useCollection = <T extends DocumentData>(
  _query: Query<T> | undefined
) => {
  const [result, setResult] = useState<{ query: Query<T>; result: Data<T>[] }>()
  const [error, setError] = useState<any>()

  const previousQuery = useRef<Query<T>>()
  const query = useMemo(() => {
    if (
      previousQuery.current &&
      _query &&
      queryEqual(previousQuery.current as any, _query as any)
    ) {
      return previousQuery.current
    } else {
      previousQuery.current = _query
      return _query
    }
  }, [_query])

  useEffect(() => {
    if (query == null) return

    const releaseOnSnapshot = query.onSnapshot(
      (snapshot) => {
        setResult((previous) => {
          const result =
            previous === undefined ||
            !queryEqual(previous.query as any, query as any)
              ? []
              : [...previous.result]
          snapshot.docChanges().forEach((change) => {
            const data = {
              ...change.doc.data(snapshotOptions),
              ref: change.doc.ref,
              id: change.doc.id,
            }
            switch (change.type) {
              case 'added': {
                result.splice(change.newIndex, 0, data)
                break
              }
              case 'modified': {
                result.splice(change.oldIndex, 1)
                result.splice(change.newIndex, 0, data)
                break
              }
              case 'removed': {
                result.splice(change.oldIndex, 1)
                break
              }
            }
          })
          return {
            query,
            result,
          }
        })
      },
      (error) => {
        setError(error)
      }
    )

    return () => {
      releaseOnSnapshot()
    }
  }, [query])

  const calledStackTrace = useMemo(() => getStackTrace(), [])
  useEffect(() => {
    if (error) {
      const useDocumentError = new FirestoreError(
        'useCollection failed',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        (query as any)?.path ?? 'unknown'
      )
      const head = (useDocumentError.stack ?? '').split('\n')[0]
      useDocumentError.stack = `${head}\n${calledStackTrace ?? ''}`
      if (error.code === 'internal') {
        console.debug(useDocumentError)
        console.error('firestore internal error')
      } else {
        console.error(useDocumentError)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const output =
    result === undefined || !queryEqual(result.query as any, query as any)
      ? undefined
      : result.result

  return [output, result === undefined, error] as const
}
