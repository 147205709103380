// eslint-disable-next-line no-restricted-imports
import { NextRouter, useRouter as _useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'

// from NextJS
interface UrlObject {
  auth?: string | null | undefined
  hash?: string | null | undefined
  host?: string | null | undefined
  hostname?: string | null | undefined
  href?: string | null | undefined
  pathname?: string | null | undefined
  protocol?: string | null | undefined
  search?: string | null | undefined
  slashes?: boolean | null | undefined
  port?: string | number | null | undefined
  query?: unknown
}

/**
 * 遷移先のURLとして問題がないか
 *
 * プロトコルを見て
 * http:, https: なら問題が無いとする。 FIXME: オープンリダイレクトの問題
 * javascript: はXSSになるので問題があるとする。
 * その他のプロトコルは想定されていないもととして弾く（一旦レポートを出す）
 */
const isSafeJumpURL = (url: string | UrlObject) => {
  const PROTOCOL_WHITE_LIST = ['http:', 'https:']
  try {
    if (typeof url === 'string') {
      const urlAsURL = new URL(url, window.document.baseURI)
      if (!PROTOCOL_WHITE_LIST.includes(urlAsURL.protocol)) {
        return false
      }
    } else {
      // プロトコルが指定されていなければサイトと同じhttpsのはず
      if (url.protocol && !PROTOCOL_WHITE_LIST.includes(url.protocol)) {
        return false
      }
    }
  } catch (error) {
    return false
  }
  return true
}

export const useRouter = (): NextRouter => {
  const _router = _useRouter()

  const push = useCallback(
    async (...args: Parameters<NextRouter['push']>) => {
      const [url] = args

      if (!isSafeJumpURL(url)) {
        console.error(`Unexpected URL`, args)
        return Promise.resolve(false)
      }

      return _router.push(...args)
    },
    [_router]
  )

  const replace = useCallback(
    async (...args: Parameters<NextRouter['replace']>) => {
      const [url] = args

      if (!isSafeJumpURL(url)) {
        console.error(`Unexpected URL`, args)
        return Promise.resolve(false)
      }

      return _router.replace(...args)
    },
    [_router]
  )

  return useMemo(
    () => ({
      ..._router,
      push,
      replace,
    }),
    [_router, push, replace]
  )
}

export type { Router, NextRouter } from 'next/router'
